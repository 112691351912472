<template>
  <div class="banner-container">
    <img src="@/assets/member/member-img.png" alt="" class="banner-img" />
    <div class="banner-block">
      <div class="block-item" v-for="(item, index) in 55" :key="index"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.banner-container {
  width: 100%;
  height: 420px;
  background-color: #f7f050;
  position: relative;
  .banner-img {
    width: 308px;
    height: 212px;
    position: absolute;
    top: 136px;
    left: calc(50% - 154px);
  }
  .banner-block {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    .block-item {
      width: 9px;
      height: 24px;
      background-color: #50b741;
      margin-right: 12px;
    }
    .block-item:last-child {
      margin-right: 0;
    }
  }
}
</style>
