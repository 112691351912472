<template>
  <a-layout class="main-container">
    <banner />
    <div class="member-benefit">
      <div class="benefit-title">会员权益</div>
      <div class="benefit-divider"></div>
      <div class="benefit-title-en">MEMBERSHIP BENEFITS</div>
      <div class="benefit-content">
        <div class="content-floor">
          <div class="content-item left">
            <div class="item-icon">
              <img src="@/assets/member/icon-regularity.png" alt="" />
            </div>
            <div class="item-name">正规</div>
            <div class="item-dec">国字号身份认证，官方权威正规，官网可查</div>
          </div>
          <div class="content-item right">
            <div class="item-icon">
              <img src="@/assets/member/icon-hoist.png" alt="" />
            </div>
            <div class="item-name">提升</div>
            <div class="item-dec">
              以会员身份参加学会举办的教师提升学习、 国家级比赛交流展览交流活动
            </div>
          </div>
        </div>
        <div class="content-floor">
          <div class="content-item left">
            <div class="item-icon">
              <img src="@/assets/member/icon-learn.png" alt="" />
            </div>
            <div class="item-name">学习</div>
            <div class="item-dec">
              会员身份进入学会官方网站、视频学习网学习交流活动
            </div>
          </div>
          <div class="content-item right">
            <div class="item-icon">
              <img src="@/assets/member/icon-recommend.png" alt="" />
            </div>
            <div class="item-name">推荐</div>
            <div class="item-dec">优先推荐在学会举办的师训中进行学习</div>
          </div>
        </div>
      </div>
      <div class="benefit-block">
        <div class="block-item" v-for="(item, index) in 55" :key="index"></div>
      </div>
    </div>
    <div class="member">
      <div class="member-public">
        <div class="info">
          <div class="info-title">会员公示</div>
          <div class="info-title-en">MEMBERS OF THE PUBLIC</div>
          <div class="btn-check" @click="onViewPublic">点击查看</div>
        </div>
        <img class="info-icon" src="@/assets/member/g.png" alt="" />
      </div>
      <div class="member-apply">
        <div class="info">
          <div class="info-title">入会申请</div>
          <div class="info-title-en">FOR MEMBERSHIP</div>
          <div class="btn-apply" @click="onApply">点击申请</div>
        </div>
        <img class="info-icon" src="@/assets/member/s.png" alt="" />
      </div>
      <div class="member-process">
        <div class="info">
          <div class="info-title">申请流程</div>
          <div class="info-title-en">APPLICATION PROCESS</div>
          <div class="btn-check" @click="onViewProcess">点击查看</div>
        </div>
        <img class="info-icon" src="@/assets/member/r.png" alt="" />
      </div>
    </div>
  </a-layout>
</template>

<script>
import Banner from "@/views/member/components/Banner.vue";

export default {
  components: {
    Banner
  },
  data() {
    return {};
  },
  methods: {
    // 查看会员公示
    onViewPublic() {
      this.$router.push({ name: "MemberPublic" });
    },
    // 查看申请流程
    onViewProcess() {
      this.$router.push({ name: "MemberProcess" });
    },
    // 申请
    onApply() {
      this.$router.push({ name: "MemberApply" });
    }
  }
};
</script>

<style scoped lang="less">
.main-container {
  min-height: 100vh;
  background-color: #f1f2f5;
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .member-benefit {
    width: 1150px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    .benefit-title {
      font-weight: 600;
      font-size: 30px;
      color: #000000;
      margin-top: 52px;
    }
    .benefit-divider {
      width: 231px;
      height: 3px;
      background-color: #000000;
      margin: 4px 0;
    }
    .benefit-title-en {
      font-weight: 600;
      font-size: 20px;
      color: #08a782;
      letter-spacing: 1px;
    }
    .benefit-content {
      margin-top: 64px;
      .content-floor {
        display: flex;
        margin-bottom: 48px;
        .content-item {
          width: 228px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .item-icon {
            width: 40px;
            height: 40px;
            border: 1px solid #979797;
            border-radius: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .item-name {
            font-weight: 300;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            margin-top: 16px;
          }
          .item-dec {
            font-weight: 300;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.65);
            margin-top: 8px;
            text-align: center;
          }
        }
        .left {
          margin-right: 99px;
        }
        .right {
          margin-left: 99px;
        }
      }
    }
    .benefit-block {
      margin-top: 38px;
      display: flex;
      justify-content: center;
      .block-item {
        width: 9px;
        height: 24px;
        background-color: #000000;
        margin-right: 12px;
      }
      .block-item:last-child {
        margin-right: 0;
      }
    }
  }

  .member {
    margin-top: 122px;
    margin-bottom: 199px;
    display: flex;
    justify-content: center;
    .member-public,
    .member-apply,
    .member-process {
      position: relative;
      width: 360px;
      height: 240px;
      .info-icon {
        position: absolute;
        width: 61px;
        height: 100px;
        top: 31px;
        left: 41px;
        z-index: 0;
      }
      .info {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 360px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .member-apply {
      background: #ffffff;
      box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.06);
      border-radius: 2px;
    }
    .info-title {
      font-weight: 400;
      font-size: 26px;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 58px;
    }
    .info-title-en {
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 1px;
    }
    .btn-check {
      margin-top: 29px;
      width: 100px;
      height: 32px;
      background-color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .btn-apply {
      margin-top: 37px;
      width: 132px;
      height: 43px;
      background-color: #08a782;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 39px;
      cursor: pointer;
    }
  }
}
</style>
